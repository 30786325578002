import React from 'react';
import ChooserContext from '../context/chooserContext';
import { graphql } from 'gatsby';
import { t } from '../helpers/language';
import { getSpecsFromProduct } from '../helpers/productHelpers';
import { motion } from 'framer-motion';
import {
  Intro,
  Section,
  Swirl,
  Card,
  Chooser,
  Prefooter,
  Grid
} from '../partials/components';
import { FaChevronRight } from 'react-icons/fa';
import { PageTransition } from '../helpers/pageTransitions';
import { getColor } from '../helpers/colors';

const SpaChooser = ({ data, pageContext, ...props }) => {
  const { chooser, products, globalConfig } = data;

  const { contactPage } = globalConfig;

  return (
    <PageTransition>
      <Intro
        title={t('spa_chooser')}
        breadcrumbs={pageContext.breadcrumbs}
        action={{
          iconAfter: <FaChevronRight />,
          gaTarget: 'intro-cta',
          children: t('spaworld_contact_cta'),
          to: contactPage.path
        }}
      >
        <Swirl toColor={getColor('light-gray')} />
      </Intro>
      <Section animate={true} padding={'double'} background={'light-gray'}>
        <ChooserContext.Consumer>
          {({
            updateChooser,
            answers,
            currentIndex,
            isComplete,
            recommendations
          }) => {
            return (
              <>
                <Chooser
                  steps={chooser.edges}
                  updateChooser={updateChooser}
                  currentIndex={currentIndex}
                  isComplete={isComplete}
                  products={products}
                  contactPagePath={contactPage.path}
                />

                <Grid>
                  {recommendations &&
                    recommendations.map((product, i) => {
                      return (
                        <motion.div
                          key={`product-${product.id}`}
                          positionTransition
                        >
                          <Card
                            heading={product.title}
                            caption={product.shortDescription}
                            image={product.heroImage}
                            items={getSpecsFromProduct(product)}
                            links={[
                              {
                                path: product.path,
                                title: t('generic_cta')
                              },
                              {
                                path: contactPage.path,
                                gaTarget: 'product-enquiry-cta',
                                state: {
                                  product: product
                                },
                                title: t('spaworld_contact_cta')
                              }
                            ]}
                            buttonsRight={true}
                          />
                        </motion.div>
                      );
                    })}
                </Grid>
              </>
            );
          }}
        </ChooserContext.Consumer>
      </Section>

      <Section background={'light-gray'}>
        <Prefooter contactPagePath={contactPage.path} />
      </Section>
    </PageTransition>
  );
};

export const query = graphql`
  query tagsByLanguage($language: String) {
    chooser: allDatoCmsChooserStep(
      filter: { locale: { eq: $language } }
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          ...ChooserSteps
        }
      }
    }

    products: allDatoCmsProduct(filter: { locale: { eq: $language } }) {
      edges {
        node {
          ...ProductCard
          tags {
            id
            slug
          }
        }
      }
    }

    globalConfig: datoCmsConfig(locale: { eq: $language }) {
      contactPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`;

export default SpaChooser;
